import React from 'react';

import { Stack, Divider, Box } from '@mui/material'
import { useResponsive } from '../hooks/useResponsive';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom'
import styled from '@emotion/styled';

const StyledLink = styled(Link)`
    text-decoration: none;
    &:focus, &:hover {
        text-decoration: underline;
    }
`;

export const FooterLink = () => {
    const { isMobile } = useResponsive()
    const {t} = useTranslation()
    const setFontSize = () => {
        if (isMobile) {
            return '0.8em'
        } else {
            return '1em'
        }
    }
    const renderLink = (title, link) => {
        return (
            <StyledLink
                to={`../${link}`}
                style={{
                    color: 'black', 
                    textAlign: 'center', 
                    fontFamily: 'Luciole',
                    fontSize: setFontSize(),
                }} 
                relative='path'
                rel='noreferrer' 
                aria-label={title}
            >
                {title}
            </StyledLink>
        )
    }
    return (
        <Stack
            component="ul"
            sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginBlockStart: 0,
                marginBlockEnd: 0,
                paddingInlineStart: 0
            }}
            direction={isMobile ? "column": "row"}
            spacing={2}
        >
            <Box component="li" sx={{listStyleType: 'none'}} >
                {renderLink(t('link.label-plan'), 'plan-du-site')}
            </Box>
            <Divider aria-hidden={true} variant='middle' orientation="vertical" sx={{bgcolor: 'black'}} flexItem />
            <Box component="li" sx={{listStyleType: 'none'}} >
                {renderLink(t('link.label-legal'), 'mentions-legales')}
            </Box>
            <Divider aria-hidden={true} variant='middle' orientation="vertical" sx={{bgcolor: 'black'}} flexItem />
            <Box component="li" sx={{listStyleType: 'none'}} >
                {renderLink(t('link.label-accessibility'), 'politique-accessibility')}
            </Box>
            <Divider aria-hidden={true} variant='middle' orientation="vertical" sx={{bgcolor: 'black'}} flexItem />
            <Box component="li" sx={{listStyleType: 'none'}} >
                {renderLink(t('link.label-personal-data'), 'personal-data')}
            </Box>
        </Stack>
    )
}