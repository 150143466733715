import React from 'react';

import { Box, Typography, Stack } from '@mui/material';
import { useResponsive } from '../hooks/useResponsive';
import { AppBarSite } from './AppBarSite'

export const HeaderTitle = ({title, description: Description}) => {
    const { isDesktop } = useResponsive()
    return (
        <>
            <AppBarSite />
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    backgroundColor: '#EFEFEF',
                    justifyContent: 'center'
                }}>
                <Stack
                    direction='column'
                    spacing={2} 
                    sx={{
                        paddingY: '4em',
                        width: isDesktop ? '70%' : '95%',
                    }} 
                >
                    <Typography component='h1' variant={isDesktop ? 'h3' : 'h4'} sx={{fontFamily: 'Luciole-Bold'}}>
                        {title}
                    </Typography>
                    {Description && 
                        <Description/>
                    }
            </Stack>
        </Box>
       </>
    )
}