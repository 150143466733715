import React from 'react';

import { Stack } from '@mui/material';
import styled from '@emotion/styled'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StyledLink = styled(Link)`
    font-family: Luciole;
    font-size: 1em;
    color: #303030;
    text-decoration: none;
    &:focus, &:hover {
        text-decoration: underline;
    }
`

export const LinkList = ({direction = 'row'}) => {
    const {t} = useTranslation();
    return (
        <Stack sx={{display: 'flex', alignItems: 'center'}} direction={direction} spacing={5}>
            <StyledLink aria-label={t('home.title')} to='../#acceuil'>{t('home.title')}</StyledLink>
            <StyledLink aria-label={t('site.menu')} to='../#sites'>{t('site.menu')}</StyledLink>
            <StyledLink aria-label={t('phone.title')} to='../#numbers'>{t('phone.title')}</StyledLink>
            <StyledLink aria-label={t('beach.title')} to='../#beach'>{t('beach.title')}</StyledLink>
        </Stack>
    )
}