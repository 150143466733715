const prod = {
  city: process.env.City || 'Hérault',
  url: {
    HANDIMAP_URL: 'https://www.herault-mobility.fr/handimap/',
  }
};
 
const dev = {
  city: process.env.City || 'Hérault',
  url: {
    HANDIMAP_URL: 'http://localhost:3000/handimap',
  }
};

export const config = process.env.REACT_APP_NODE_ENV === 'development' ? dev : prod;