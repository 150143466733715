import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-http-backend';

import translationEN from './en.json';
import translationFR from './fr.json';
import translationES from './es.json';
 
// let defaultLanguage = 'fr';
 
// the translations
const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  },
  es: {
    translation: translationES
  }
};

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
};


 
i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    detection: options,
    fallbackLng: 'en',
    supportedLngs: ['fr', 'en', 'es'],
    
    keySeparator: '.',  // to support nested translations
 
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
 
export default i18n;