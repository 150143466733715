import React, { useEffect } from 'react';

import { Box, Typography, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next';

import { HeaderTitle } from '../containers/HeaderTitle';
import { Footer } from '../containers/Footer';
import { useResponsive } from '../hooks/useResponsive';

const DescriptionHeader = () => {
    const { isDesktop } = useResponsive();
    return (
        <Typography  variant="body1" style={{fontFamily: 'Luciole', fontSize: isDesktop ? '1.5em': '1em'}}>
            <p>Le contenu de ce site est créé, géré et actualisé par Hérault Tourisme, Agence de Développement Touristique du Département de l'Hérault.</p>
            <p>Vous y accédez par l'adresse suivante : <a href="https://www.herault-mobility.fr/">www.herault-mobility.fr</a></p>
        </Typography>   
    )
}

export const RGPDSite = () => {
    const { t } = useTranslation();
    const { isDesktop } = useResponsive();

    useEffect(() => {
        window.scrollTo({
            top: 0
        })
    }, []);

    useEffect(() => {
        document.title=`${t('link.label-personal-data')} | ${t('common.aria-logo')}`
    }, [t])

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <HeaderTitle title={t('link.label-personal-data')} description={DescriptionHeader} />
            <Box sx={{display: 'flex', width: '100%', justifyContent: 'center', paddingY: '2em'}}>
                <Box sx={{display: 'flex', width: isDesktop ? '70%' : '95%', paddingX: '.5em'}}>
                    <Stack sx={{display: 'flex', width: '100%', justifyContent: 'center'}} direction='column' spacing={2}>
                        <Typography variant='h2' sx={{textDecoration: 'underline', fontSize: '1.5em', fontFamily: 'Luciole-Bold'}}>Exercer vos droits</Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            Hérault Tourisme a désigné un référent à la protection des données.
                            <br/>
                            Les coordonnées du référent à la protection des données sont les suivantes :
                            <br/>
                            <br/>
                            Nom : COUSTOL Jean-Jacques
                            <br/>
                            Adresse e-mail : <a href="mail:jjcoustol@herault-tourisme.com">jjcoustol@herault-tourisme.com</a>
                            <br/>
                            Tél: <a href="tel:+334 67 67 71 13">+334 67 67 71 13</a>
                        </Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            <Typography variant='span' sx={{textDecoration: 'underline'}}>Droit d’introduire une réclamation auprès de la Cnil</Typography>
                            <br/>
                            Les clients et prospects concernés par le traitement de leurs données à caractère personnel sont informés de leur droit d’introduire une plainte auprès d'une autorité de contrôle, à savoir la Cnil en France, si ces derniers estiment que le traitement de données à caractère personnel les concernant n'est pas conforme à la règlementation européenne de protection des données, à l’adresse suivante :
                            <br/>
                            <br/>
                            CNIL – Service des plaintes
                            <br/>
                            3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07
                            <br/>
                            Tél: <a href="tel:01 53 73 22 22">01 53 73 22 22</a>
                        </Typography>
                        <Typography variant='h2' sx={{textDecoration: 'underline', fontSize: '1.5em', fontFamily: 'Luciole-Bold'}}>À propos des cookies</Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            Nous utilisons des <a href="https://www.cnil.fr/fr/definition/cookie">cookies</a> (Google Analytics) sur le site pour mesurer l’audience du site.
                            <br/>
                            <br/>
                            <Typography variant='span' sx={{fontFamily: 'Luciole-Bold-Italic'}}>Qu'est-ce qu'un "cookie" ?</Typography>
                            <br/>
                            Un "cookie" est une suite d'informations, généralement de petite taille et identifié par un nom, qui peut être transmis à votre navigateur par un site web sur lequel vous vous connectez. Votre navigateur web le conservera pendant une certaine durée, et le renverra au serveur web chaque fois que vous vous y re-connecterez. Les cookies ont de multiples usages : ils peuvent servir à mémoriser votre identifiant client auprès d'un site marchand, le contenu courant de votre panier d'achat, un identifiant permettant de tracer votre navigation pour des finalités statistiques ou publicitaires, etc. 
                            <br/>
                            <a href="https://www.cnil.fr/fr/cookies-et-autres-traceurs/comment-se-proteger/maitriser-votre-navigateur">En savoir plus sur les cookies et leur fonctionnement</a>
                        </Typography>
                        <Typography variant='h2' sx={{textDecoration: 'underline', fontSize: '1.5em', fontFamily: 'Luciole-Bold'}}>Type de cookie déposé sur le site Hérault mobility :</Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            Nous utilisons des <a href="https://www.cnil.fr/fr/definition/cookie">cookies</a> (Google Analytics) sur le site pour mesurer l’audience du site.
                            <br/>
                            <br/>
                            <Typography variant='span' sx={{textDecoration: 'underline'}}>Cookies strictement nécessaires au site pour fonctionner</Typography>
                            <br/>
                            Ces cookies permettent aux services principaux du site de fonctionner de manière optimale. Vous pouvez techniquement les bloquer en utilisant les paramètres de votre navigateur mais votre expérience sur le site risque d’être dégradée. 
                            <br/>
                            <br/>
                            Par ailleurs, vous avez la possibilité de vous opposer à l’utilisation des traceurs de mesure d’audience strictement nécessaires au fonctionnement et aux opérations d’administration courante du site web dans la fenêtre de gestion des cookies en cliquant sur l'image représentant un cookie en bas à gauche de l'écran.
                        </Typography>

                        <Typography variant='h3' sx={{textDecoration: 'underline', fontSize: '1em', fontFamily: 'Luciole-Bold'}}>Cookies techniques</Typography>
                        <TableContainer component={Paper}>
                            <Table sx={{ width: '100%' }} aria-label='Tableau des cookies du site'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{fontFamily: 'Luciole-Bold'}}>Nom du cookie</TableCell>
                                        <TableCell sx={{fontFamily: 'Luciole-Bold'}} align="left">Finalité</TableCell>
                                        <TableCell sx={{fontFamily: 'Luciole-Bold'}} align="left">Durée de conservation</TableCell>
                                    </TableRow>
                                </TableHead>
                                
                                <TableBody>
                                    <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell sx={{fontFamily: 'Luciole'}} component="th" scope="row">
                                            Tarteaucitron
                                        </TableCell>
                                        <TableCell sx={{fontFamily: 'Luciole'}} align="left">Sauvegarde vos choix en matière de consentement des cookies</TableCell>
                                        <TableCell sx={{fontFamily: 'Luciole'}} align="left">6 mois</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Typography variant='h3' sx={{textDecoration: 'underline', fontSize: '1em', fontFamily: 'Luciole-Bold'}}>Cookies de mesure d’audience</Typography>
                        <TableContainer component={Paper}>
                            <Table sx={{ width: '100%' }} aria-label='Tableau des cookies du site'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{fontFamily: 'Luciole-Bold'}}>Nom du cookie</TableCell>
                                        <TableCell sx={{fontFamily: 'Luciole-Bold'}} align="left">Finalité</TableCell>
                                        <TableCell sx={{fontFamily: 'Luciole-Bold'}} align="left">Durée de conservation</TableCell>
                                    </TableRow>
                                </TableHead>
                                
                                <TableBody>
                                    <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell sx={{fontFamily: 'Luciole'}} component="th" scope="row">
                                            _ga
                                        </TableCell>
                                        <TableCell sx={{fontFamily: 'Luciole'}} align="left">Permet de stocker votre identifiant unique de visite</TableCell>
                                        <TableCell sx={{fontFamily: 'Luciole'}} align="left">6 mois</TableCell>
                                    </TableRow>
                                    <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell sx={{fontFamily: 'Luciole'}} component="th" scope="row">
                                            _ga_FTSXEVPNWL
                                        </TableCell>
                                        <TableCell sx={{fontFamily: 'Luciole'}} align="left">Permet le stockage temporaire de vos données de visite</TableCell>
                                        <TableCell sx={{fontFamily: 'Luciole'}} align="left">6 mois</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Stack>
                </Box>
            </Box>
            <Footer />
        </Box>
    )
}