import { useEffect } from 'react';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import i18n from './i18n/i18n'
import { SiteMap } from './pages/SiteMap';
import { SiteAccessibility } from './pages/AccessibilitySite';
import { SiteLegal } from './pages/LegalSite';
import { SiteHome } from './pages/HomeSite';
import { RGPDSite } from './pages/RGPDSite';

function App() {

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const router = createBrowserRouter([
    {
      path: '/',
      element: <SiteHome />
    },
    {
      path: 'plan-du-site',
      element: <SiteMap />,
    },
    {
      path: 'mentions-legales',
      element: <SiteLegal />
    }, {
      path: 'politique-accessibility',
      element: <SiteAccessibility />
    },{
      path: 'personal-data',
      element: <RGPDSite />
    }
  ])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const lng = queryParams.get('lng')
    if (lng !== null && typeof lng === 'string') {
      window.history.replaceState({}, '', '/');
    } else {
      return;
    }
    document.documentElement.lang = getLanguage();
  }, [])

  return (
      <RouterProvider router={router} />
  )

}

export default App;
