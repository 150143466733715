import * as React from 'react';

import { Link, Box, ListItem, ListItemIcon, ListItemButton } from '@mui/material';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import HomeIcon from '@mui/icons-material/Home';

import { useTranslation } from 'react-i18next';
import { useResponsive } from '../hooks/useResponsive';

const NavLink = ({value, label, handleClick, active, icon}) => {
  
  const { isDesktop } = useResponsive()

  const style = {
    tab: {
      display: 'flex', 
      alignItems: 'flex-end', 
      color: active ? '#0d58a6' : 'black', 
      '&:hover': { color: '#0d58a6' }
    },
    link: {
      display: 'flex',
      color: 'inherit',
      fontFamily: 'Luciole',
      fontSize: '1rem',
      textAlign: 'center',
      textTransform: 'uppercase',
      '&: hover': {
        color: '#0d58a6',
        cursor: 'pointer'
      }
    },
    icon: {
      minWidth: '2em', 
      minHeight: '1.5em', 
      color: 'inherit',  
    },
    item: {
      display: 'block', 
      alignSelf: 'center',
      height: 'max-content', 
      maxWidth: 'max-content',
    },
  }

  const renderItems = () => {
    return (
      <>
        <Box sx={style.tab} tabIndex={isDesktop ? null : '-1'}>
            <ListItemIcon sx={style.icon} >
              {icon}
            </ListItemIcon>
          <Link 
            sx={style.link} 
            variant='text'
            href={`#${value}`}
            tabIndex={isDesktop ? null : '-1'}
            onClick={e => {
              e.preventDefault()
            }}
            aria-current={active}
            underline='none'
          >
            {label}
          </Link>
        </Box>
        {active ? (
          <Box sx={{display: 'flex', width: '100%', backgroundColor: '#0d58a6', height: 2, marginTop: '.5em'}} />
        ) : null}
      </>
    )
  }

  const renderItemDesktop = () => {
    return (
      <ListItem
      sx={style.item} 
      onClick={() => {
        handleClick(value)
      }}
      onKeyDown={(e) => {
        if(e.key === 'Enter') {
          handleClick(value)
        }
      }}
    >
      {renderItems()}
    </ListItem>
    )
  }

  const renderItemMobile = () => {
    return (
      <ListItemButton
      sx={style.item} 
      onClick={() => {
        handleClick(value)
      }}
      onKeyDown={(e) => {
        if(e.key === 'Enter') {
          handleClick(value)
        }
      }}
      autoFocus={active}
    >
      {renderItems()}
    </ListItemButton>
    )
  }
  
  return (
    <>
      {isDesktop ? (renderItemDesktop()) : (renderItemMobile())}
    </>
  )
}


export default function BasicTabs({ position, handlePosition }) {

  const {t} = useTranslation();

  return (
    <>
        <NavLink value='acceuil' icon={<HomeIcon />} active={position === 'acceuil'} label={t('home.title')}  handleClick={handlePosition} />
        <NavLink value='sites' icon={<TravelExploreIcon />} active={position === 'sites'} label={t('site.menu')} handleClick={handlePosition} />
        <NavLink value='numbers' icon={<PhoneAndroidIcon />} active={position === 'numbers'} label={t('phone.title')} handleClick={handlePosition} />
        <NavLink value='beach' icon={<BeachAccessIcon />}  active={position === 'beach'} label={t('beach.title')} handleClick={handlePosition} />
    </>
  );
}