import React, { useState } from 'react';

import { Box, AppBar, Toolbar, Stack, IconButton, Menu } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';


import { LanguageSelector } from '../components/LanguageSelector';
import { useResponsive } from '../hooks/useResponsive';
import { LinkList } from '../components/LinkList';

export const AppBarSite = () => {
    const { isDesktop } = useResponsive();

    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
      };
    
      const handleCloseNavMenu = () => {
        setAnchorElNav(null);
      };

    return (
        <header role='banner'>
            <AppBar 
                sx={{
                    display: 'flex',
                    backgroundColor: 'white', 
                    paddingY: '1em', 
                    maxWidth: '100%'
                }}
                position='relative'
                >
                <Toolbar>
                    {isDesktop ? (
                        <Box sx={{display: 'flex', width: '100%'}}>
                            <Box sx={{display: 'flex', flexGrow: 1, minWidth: 'max-content'}}>
                                <Stack sx={{display: 'flex', alignItems: 'center'}} direction='row' spacing={8}>
                                    <object width='130em' type="image/svg+xml" data="logo-herault-mobility.svg" aria-hidden="true" title='herault-logo' />
                                    <LinkList />
                                </Stack>
                            </Box>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <LanguageSelector/>
                            </Box>
                        </Box>
                    ) : (
                        <Box sx={{display: 'flex', width: '100%', paddingX: '.5em', alignItems: 'center'}}>
                            <Box sx={{display: 'flex', flexGrow: 1}}>
                                <Box 
                                    component="img"
                                    sx={{
                                    paddingX: '0.5em',
                                    width: '6em'
                                    }} 
                                    src="logo-herault-mobility.png"
                                    alt=""
                                />
                            </Box>
                            <Box sx={{display: 'flex', alignItems: 'center', marginX: '1em'}}>
                                <LanguageSelector/>
                            </Box>
                            <Box>
                                <IconButton
                                    size="large"
                                    sx={{
                                    color: '#0d58a6',
                                    border: '1px solid #0d58a6'
                                    }}
                                    aria-label="burger-icon"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                >
                                    <MenuIcon />
                                </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        MenuListProps={{
                                            sx: {
                                                paddingX: '1em',
                                                paddingY: '.5em'
                                            }
                                        }}
                                        open={Boolean(anchorElNav)}
                                        onClose={handleCloseNavMenu}
                                        sx={{
                                            display: 'block'
                                        }}
                                >
                                    <LinkList 
                                        direction='column'
                                    />
                                </Menu>
                            </Box>
                        </Box>
                    )}
                </Toolbar>
            </AppBar>
        </header>
    )
}