import React, { useEffect } from 'react';

import { Box, Stack, Typography, List, ListItem } from '@mui/material'
import { useTranslation, Trans } from 'react-i18next';

import { HeaderTitle } from '../containers/HeaderTitle';
import { Footer } from '../containers/Footer';
import { useResponsive } from '../hooks/useResponsive';


export const SiteAccessibility = () => {
    const { t } = useTranslation();
    const { isDesktop } = useResponsive();
    

    useEffect(() => {
        window.scrollTo({
            top: 0
        })
    }, [])

    useEffect(() => {
        document.title=`${t('link.accessibility')} | ${t('common.aria-logo')}`
    }, [t])

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
           <HeaderTitle title={t('accessibility.page_accessibility')}/>
           <Box sx={{display: 'flex', width: '100%', justifyContent: 'center', paddingY: '2em'}}>
                <Box sx={{display: 'flex', width: isDesktop ? '70%' : '95%', paddingX: '.5em'}}>
                    <Stack sx={{display: 'flex', width: '100%', justifyContent: 'center'}} direction='column' spacing={2}>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            {t('accessibility.not_help_1')}
                        </Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            {t('accessibility.page_object')}                   
                        </Typography>
                        <Typography variant={isDesktop ? 'h4' : 'h5'} component='h2' sx={{fontFamily: 'Luciole-Bold'}}>
                            {t('accessibility.header_definition')} 
                        </Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                           {t('accessibility.defi_1')}
                        </Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                           {t('accessibility.defi_2')}
                        </Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            {t('accessibility.site_access_explain')}
                        </Typography>
                        <List sx={{
                        paddingLeft: '3em',                        
                        listStyleType: 'disc',
                        '& .MuiListItem-root': {
                        display: 'list-item',
                        fontFamily: 'Luciole'
                        },
                        }}>
                            <ListItem>
                                {t('accessibility.site_access_1')}
                            </ListItem>
                            <ListItem>
                                {t('accessibility.site_access_2')}
                            </ListItem>
                            <ListItem>
                                {t('accessibility.site_access_3')}
                            </ListItem>
                            <ListItem>
                                {t('accessibility.site_access_4')}
                            </ListItem>
                            <ListItem>
                                Etc.
                            </ListItem>
                        </List>
                        <Typography variant={isDesktop ? 'h4' : 'h5'} component='h2' sx={{fontFamily: 'Luciole-Bold'}}>
                            {t('accessibility.header_access_enga')}
                        </Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            {t('accessibility.access_enga')}
                            <a href='https://www.legifrance.gouv.fr/loda/article_lc/LEGIARTI000037388867' aria-label='legifrance' target='_blank' rel="noreferrer">
                            {t('accessibility.link_enga')}
                            </a>
                        </Typography>
                        <Typography variant={isDesktop ? 'h4' : 'h5'} component='h2' sx={{fontFamily: 'Luciole-Bold'}}>
                            {t('accessibility.header_access_declare')}
                        </Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            {t('accessibility.access_declare')} «<a href='www.herault-mobility.fr' aria-label='herault' target='_blank' rel="noreferrer">www.herault-mobility.fr</a>».
                        </Typography>
                        <Typography variant={isDesktop ? 'h4' : 'h5'} component='h2' sx={{fontFamily: 'Luciole-Bold'}}>
                            {t('accessibility.header_status')}
                        </Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            <Trans i18nKey='accessibility.status_1'/> <a href='https://www.numerique.gouv.fr/publications/rgaa-accessibilite/methode-rgaa/criteres/' aria-label='rgaa-accessibilite'>{t('accessibility.status_link')}</a> {t('accessibility.status_2')}
                        </Typography>
                        <Typography variant={isDesktop ? 'h4' : 'h5'} component='h2' sx={{fontFamily: 'Luciole-Bold'}}>{t('accessibility.header_res')}</Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            {t('accessibility.res')} <a href='ideance.net' aria-label='ideance' target='_blank' rel="noreferrer">Ideance</a> <Trans i18nKey='accessibility.res_2'>{{score: 83}}</Trans>
                        </Typography>
                        <Typography variant={isDesktop ? 'h4' : 'h5'} component='h2' sx={{fontFamily: 'Luciole-Bold'}}>{t('accessibility.header_non_access')}</Typography>
                        <Typography variant={isDesktop ? 'h5' : 'h6'} component='h3' sx={{fontFamily: 'Luciole-Bold'}}>{t('accessibility.sub_header_non_con')}</Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>{t('accessibility.list_non_con')}</Typography>
                        <List sx={{
                        paddingLeft: '3em',                        
                        listStyleType: 'disc',
                        '& .MuiListItem-root': {
                        display: 'list-item',
                        fontFamily: 'Luciole'
                        },
                        }}>
                            <ListItem>
                                3.2 - {t('accessibility.non_con_3.2')}
                            </ListItem>
                            <ListItem>
                                3.3 - {t('accessibility.non_con_3.3')}
                            </ListItem>
                            <ListItem>
                                7.4 - {t('accessibility.non_con_7.4')}
                            </ListItem>
                            <ListItem>
                                8.2 - {t('accessibility.non_con_8.2')}                           
                            </ListItem>
                            <ListItem>
                                8.7 - {t('accessibility.non_con_8.7')}                            
                            </ListItem>
                            <ListItem>
                                9.3 - {t('accessibility.non_con_9.3')}                           
                            </ListItem>
                            <ListItem>
                                10.7 - {t('accessibility.non_con_10.7')}                            
                            </ListItem>
                            <ListItem>
                                12.8 - {t('accessibility.non_con_12.8')}                            
                            </ListItem>
                            <ListItem>
                                13.3 - {t('accessibility.non_con_13.3')}                         
                            </ListItem>
                        </List>
                        <Typography variant={isDesktop ? 'h5' : 'h6'} component='h3' sx={{fontFamily: 'Luciole-Bold'}}>
                            {t('accessibility.sub_header_con_not_submitted')}
                        </Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            {t('accessibility.con_not_submitted_list')}
                        </Typography>
                        <List sx={{
                        paddingLeft: '3em',                        
                        listStyleType: 'disc',
                        '& .MuiListItem-root': {
                        display: 'list-item',
                        fontFamily: 'Luciole'
                        },
                        }}>
                            <ListItem>
                                {t('accessibility.con_not_submitted_1')}
                            </ListItem>
                            <ListItem>
                                {t('accessibility.con_not_submitted_2')}
                            </ListItem>
                        </List>
                        <Typography variant={isDesktop ? 'h4' : 'h5'} component='h2' sx={{fontFamily: 'Luciole-Bold'}}>
                            {t('accessibility.header_remarque_com')}
                        </Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            {t('accessibility.remarque_on')} « <a href='https://www.herault-mobility.fr/handimap' aria-label='handimap' target='_blank' rel="noreferrer">Handimap - Tourisme Hérault mobility</a> » {t('accessibility.remarque_com')}
                        </Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            {t('accessibility.remarque_com_2')}
                        </Typography>
                        <Typography variant={isDesktop ? 'h4' : 'h5'} component='h2' sx={{fontFamily: 'Luciole-Bold'}}>
                            {t('accessibility.header_time_declare')}
                        </Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            {t('accessibility.time_declare')}
                        </Typography>
                        <Typography variant={isDesktop ? 'h5' : 'h6'} component='h3' sx={{fontFamily: 'Luciole-Bold'}}>
                            {t('accessibility.sub_header_tech')}
                        </Typography>
                        <List sx={{
                        paddingLeft: '3em',                        
                        listStyleType: 'disc',
                        '& .MuiListItem-root': {
                        display: 'list-item',
                        fontFamily: 'Luciole'
                        },
                        }}>
                            <ListItem>
                                HTML5                            
                            </ListItem>
                            <ListItem>
                                SVG                            
                            </ListItem>
                            <ListItem>
                                ARIA                            
                            </ListItem>
                            <ListItem>
                                CSS                            
                            </ListItem>
                            <ListItem>
                                JavaScript                            
                            </ListItem>
                        </List>
                        <Typography variant={isDesktop ? 'h5' : 'h6'} component='h3' sx={{fontFamily: 'Luciole-Bold'}}>
                            {t('accessibility.sub_header_env')}
                        </Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            {t('accessibility.env')}
                        </Typography>
                        <List sx={{
                        paddingLeft: '3em',                        
                        listStyleType: 'disc',
                        '& .MuiListItem-root': {
                        display: 'list-item',
                        fontFamily: 'Luciole'
                        },
                        }}>
                            <ListItem>
                                Firefox 105.0.3 {t('common.and')} NVDA 2022.3                            
                            </ListItem>
                            <ListItem>
                                Firefox 105.0.3 {t('common.and')} JAWS 2021                            
                            </ListItem>
                            <ListItem>
                                Safari {t('common.and')} VoiceOver (macOS 12.6)                            
                            </ListItem>
                            <ListItem>
                                Safari {t('common.and')} VoiceOver (iOS 16)                            
                            </ListItem>
                        </List>
                        <Typography variant={isDesktop ? 'h5' : 'h6'} component='h3' sx={{fontFamily: 'Luciole-Bold'}}>
                            {t('accessibility.sub_header_tools')}
                        </Typography>
                        <List sx={{
                        paddingLeft: '3em',                        
                        listStyleType: 'disc',
                        '& .MuiListItem-root': {
                        display: 'list-item',
                        fontFamily: 'Luciole'
                        },
                        }}>
                            <ListItem>
                                Colour Contrast Analyser                          
                            </ListItem>
                            <ListItem>
                                Contrast Finder                           
                            </ListItem>
                            <ListItem>
                                Outils de développement Firefox                          
                            </ListItem>
                            <ListItem>
                                Web Developer (extension Firefox)                            
                            </ListItem>
                        </List>

                        <Typography variant={isDesktop ? 'h5' : 'h6'} component='h3' sx={{fontFamily: 'Luciole-Bold'}}>
                            {t('accessibility.sub_header_page')}
                        </Typography>
                        <List sx={{
                        paddingLeft: '3em',                        
                        listStyleType: 'disc',
                        '& .MuiListItem-root': {
                        display: 'list-item',
                        fontFamily: 'Luciole'
                        },
                        }}>
                           <ListItem>
                                <a href='https://www.herault-mobility.fr' aria-label='herault' target='_blank' rel="noreferrer">
                                    {t('accessibility.page_home')}
                                </a>                           
                            </ListItem>
                            <ListItem>
                                <a href='https://www.herault-mobility.fr/handimap' aria-label='handimap' target='_blank' rel="noreferrer">
                                    {t('accessibility.page_handimap_modal')}
                                </a>                    
                            </ListItem>
                            <ListItem>
                                <a href='https://www.herault-mobility.fr/handimap' aria-label='handimap' target='_blank' rel="noreferrer">
                                    {t('accessibility.page_handimap_calcul')}
                                </a>                         
                            </ListItem>
                            <ListItem>
                            <a href='https://www.herault-mobility.fr/handimap' aria-label='handimap' target='_blank' rel="noreferrer">
                                {t('accessibility.page_handimap_menu')}
                            </a>                           
                            </ListItem>
                            <ListItem>
                                <a href='https://www.herault-mobility.fr/handimap' aria-label='handimap' target='_blank' rel="noreferrer">
                                    {t('accessibility.page_handimap_result')}
                                </a>                    
                            </ListItem>
                            <ListItem>
                                <a href='https://www.herault-mobility.fr/handimap' aria-label='handimap' target='_blank' rel="noreferrer">
                                    {t('accessibility.page_handimap_route')}
                                </a>                    
                            </ListItem>
                            <ListItem>
                                <a href='http://www.herault-mobility.fr/politique-accessibility' aria-label='herault-accessibility' target='_blank' rel="noreferrer">
                                    {t('accessibility.page_accessibility')}
                                </a>                  
                            </ListItem>
                        </List>
                        <Typography variant={isDesktop ? 'h4' : 'h5'} component='h2' sx={{fontFamily: 'Luciole-Bold'}}>
                            {t('accessibility.header_return')}
                        </Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            {t('accessibility.return')}
                        </Typography>
                        <List sx={{
                        paddingLeft: '3em',                        
                        listStyleType: 'disc',
                        '& .MuiListItem-root': {
                        display: 'list-item',
                        fontFamily: 'Luciole'
                        },
                        }}>
                            <ListItem>
                                {t('accessibility.return_mail')} : <a href='mailto: contact@someware.fr' aria-label='mail' style={{color: 'black'}}>contact@someware.fr</a>                    
                            </ListItem>
                            <ListItem>
                                {t('accessibility.return_letter')} : <b>Someware, 11 Rue Julien Gracq, 35690 ACIGNE</b>                           
                            </ListItem>
                        </List>
                        <Typography variant={isDesktop ? 'h4' : 'h5'} component='h2' sx={{fontFamily: 'Luciole-Bold'}}>
                            {t('accessibility.header_voie')}
                        </Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            {t('accessibility.voie_1')}
                        </Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            {t('accessibility.voie_2')}
                        </Typography>
                        <List sx={{
                        paddingLeft: '3em',                        
                        listStyleType: 'disc',
                        '& .MuiListItem-root': {
                        display: 'list-item',
                        fontFamily: 'Luciole'
                        },
                        }}>
                            <ListItem>
                                <a href='https://formulaire.defenseurdesdroits.fr/code/afficher.php?ETAPE=accueil_2016' aria-label='mail' style={{color: 'black'}}>{t('accessibility.voie_list_1')}</a>
                            </ListItem>
                            <ListItem>
                                <a href='https://www.defenseurdesdroits.fr/saisir/delegues' aria-label='contact_defense' style={{color: 'black'}}>{t('accessibility.voie_list_2')}</a>
                            </ListItem>
                            <ListItem>
                            {t('accessibility.voie_list_3')} <br/>Défenseur des droits<br/> Libre réponse 71120<br/> 75342 Paris CEDEX 07
                            </ListItem>
                        </List>
                    </Stack>
                </Box>
           </Box>
           <Footer />
        </Box>
    )
}