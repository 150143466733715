import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ReactCountryFlag from 'react-country-flag';

const languageList = {
  FR: 'fr',
  GB: 'en',
  ES: 'es'
};

export const LanguageSelector = () => {
  const { i18n, t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [langLabel, setLangLabel] = useState(t(`language-selector.aria-${lang}-flag`));

  const changeValueLang = lang => {
    setLang(lang);
    i18n.changeLanguage(lang);
    document.documentElement.lang = lang;
    setLangLabel(t(`language-selector.aria-${lang}-flag`));
  };

  const changeLanguage = evt => {
    let language = evt.target.value;
    changeValueLang(language);
  };  
  
  return (
    <Box sx={{'& .MuiTextField-root': { width: '5em' }}}>
      <TextField   
        id='language-select'
        value={lang}
        size='small'
        onChange={changeLanguage}
        sx= {{
          backgroundColor: 'white',
        }}
        inputProps={{ sx: { padding: '10px !important' },  'aria-label': t('language-selector.label', {lang: langLabel}) }}
        select
      >
        {
          Object.entries(languageList).map(lang => {
            return (
              <MenuItem key={lang[1]} value={lang[1]}>
                <ReactCountryFlag 
                  countryCode={lang[0]}
                  svg
                  style={{
                    borderRadius: 8,
                    fontSize: '1.8em',
                  }}
                  alt={t(`language-selector.aria-${lang[1]}-flag`)} />
              </MenuItem>
            );
          })
        }
      </TextField>
    </Box>
  );
};