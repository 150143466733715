import React, { useEffect } from 'react';

import { Box, Typography, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next';

import { HeaderTitle } from '../containers/HeaderTitle';
import { Footer } from '../containers/Footer';
import { useResponsive } from '../hooks/useResponsive';

const DescriptionHeader = () => {
    const { isDesktop } = useResponsive();
    return (
        <Typography  variant="body1" style={{fontFamily: 'Luciole', fontSize: isDesktop ? '1.5em': '1em'}}>
            <p>Le contenu de ce site est créé, géré et actualisé par Hérault Tourisme, Agence de Développement Touristique du Département de l'Hérault.</p>
            <p>Vous y accédez par l'adresse suivante : <a href="https://www.herault-mobility.fr/">www.herault-mobility.fr</a></p>
        </Typography>   
    )
}

export const SiteLegal = () => {
    const { t } = useTranslation();
    const { isDesktop } = useResponsive();

    useEffect(() => {
        window.scrollTo({
            top: 0
        })
    }, []);
    
    useEffect(() => {
        document.title=`${t('link.label-legal')} | ${t('common.aria-logo')}`
    }, [t])

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <HeaderTitle title={t('link.label-legal')} description={DescriptionHeader} />
            <Box sx={{display: 'flex', width: '100%', justifyContent: 'center', paddingY: '2em'}}>
                <Box sx={{display: 'flex', width: isDesktop ? '70%' : '95%', paddingX: '.5em'}}>
                    <Stack sx={{display: 'flex', width: '100%', justifyContent: 'center'}} direction='column' spacing={2}>
                    <Typography component='h2' sx={{textDecoration: 'underline', fontSize: '1.5em', fontFamily: 'Luciole-Bold'}}>Editeur du site</Typography>
                        <Typography component='body1' sx={{fontFamily: 'Luciole'}}>
                            Hérault Tourisme - Agence de Développement Touristique
                            <br/>
                            Maison du Tourisme
                            <br/>
                            Avenue des Moulins
                            <br/>
                            34184 MONTPELLIER Cedex 4
                            <br/>
                            Tél: <a href="tel:04 67 67 71 71">04 67 67 71 71</a> / Fax:  <a href="fax:04 67 67 71 71">04 67 67 71 17</a>
                            <br/>
                            <br/>
                            SIRET : 322 148 834 00029 APE : 633Z
                            <br/>
                            <br/>
                            représenté par Monsieur Pascal SCHMID, Directeur Général.
                            Directeur de publication : Le Président de Hérault Tourisme.
                            <br/>
                            <br/>
                            Ce site est édité par Hérault Tourisme
                        </Typography>
                        
                        <Typography component='h2' sx={{textDecoration: 'underline', fontSize: '1.5em', fontFamily: 'Luciole-Bold'}}>Hébergement</Typography>
                        <Typography component='body1' sx={{fontFamily: 'Luciole'}}>
                            Ikoula
                            <br/>
                            2 Cité Paradis
                            <br/>
                            75010 PARIS
                            <br/>
                            Mail de contact RGPD: <a href="mail:dpo@ikoula.com">dpo@ikoula.com</a>
                            <br/>
                            <a href="https://www.ikoula.com/">www.ikoula.com</a>
                        </Typography>

                        <Typography component='h2' sx={{textDecoration: 'underline', fontSize: '1.5em', fontFamily: 'Luciole-Bold'}}>Développement</Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            Someware
                            <br/>
                            11 Rue Julien Gracq
                            <br/>
                            35690 ACIGNE
                            <br/>
                            Mail de contact: <a href="mail:contact@someware.fr">contact@someware.fr</a>
                            <br/>
                            <a href="https://www.someware.fr/">www.someware.fr</a>
                        </Typography>

                        <Typography component='h2' sx={{textDecoration: 'underline', fontSize: '1.2em', fontFamily: 'Luciole-Bold'}}>1. COPYRIGHT DES DROITS D’AUTEURS</Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                                L'ensemble des contenus, pages, scripts, icônes ou sons de ce site sont la propriété exclusive de l’agence De Développement Hérault Tourisme de l'Hérault ou de ses partenaires. Toute production, reproduction ou représentation de ce site, en tout ou partie (textes, sons ou images), sur quelque support que ce soit est interdite. Le non-respect de cette interdiction constitue une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur.
                                <br/>
                                <br/>
                                L’agence de Développement Touristique Hérault Tourisme n'est pas responsable du contenu de tout autre site auquel vous pourriez avoir accès via le site <a href='https://www.herault-mobility.fr/'>www.herault-mobility.fr</a>
                        </Typography>

                        <Typography component='h2' sx={{textDecoration: 'underline', fontSize: '1.2em', fontFamily: 'Luciole-Bold'}}>2. CONTENUS ET INFORMATIONS EDITORIALES DE MISE À JOUR </Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                            Le contenu du site est donné à titre informatif. Ces informations ne sont pas à caractère contractuel et ne sont pas exhaustives. L’agence de Développement touristique ne peut être tenu pour responsable de l’inexactitude, erreurs, omissions des données diffusées sur un site. Toutefois l’équipe éditoriale porte beaucoup d’attention au caractère d’exactitude, n’hésitez pas si vous trouviez une information incorrecte dans nos pages à nous le faire savoir.
                        </Typography>

                        <Typography component='h2' sx={{textDecoration: 'underline', fontSize: '1.2em', fontFamily: 'Luciole-Bold'}}>3. OFFRES COMMERCIALES – CONDITIONS DE VENTE </Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                                Offres commerciales proposées sur le site et liens vers des professionnels du tourisme :
                                <br/>
                                <br/>
                                L’agence de Développement touristique a pris toutes les mesures à sa disposition pour vérifier l'exactitude des informations commerciales présentes sur son site web et/ou présentées via un lien vers un tiers depuis son site web. Ces informations peuvent être d'ordre tarifaire, descriptive, photographiques et/ou illustratives (vidéo, son, etc...). Certaines informations peuvent être erronées et/ou incomplètes. Hérault Tourisme ne peut être tenu responsable de changements ne lui ayant pas été communiqués ou de renseignements erronés. Nous vous conseillons de vérifier auprès des prestataires.
                                <br/>
                                <br/>
                                Responsabilité de l’agence de Développement Touristique Hérault Tourisme.
                                L’agence a apporté le plus grand soin à la création et à la réalisation du Site. Néanmoins, elle ne saurait vous garantir le caractère exact et exhaustif des informations fournies sur le Site
                                <br/>
                                <br/>
                                Malgré tous ses efforts, elle ne peut garantir que le Site soit sans virus et ne pourra en aucun cas être tenue pour responsable des dommages en résultant.
                        </Typography>

                        <Typography component='h2' sx={{textDecoration: 'underline', fontSize: '1.2em', fontFamily: 'Luciole-Bold'}}>4. MODIFICATION ET MISE A JOUR DU SITE </Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>                          
                                L’agence Hérault Tourisme se réserve le droit de modifier, de mettre à jour ou d'interrompre temporairement ou non tout ou partie du Site et ce, sans information préalable.
                        </Typography>

                        <Typography component='h2' sx={{textDecoration: 'underline', fontSize: '1.2em', fontFamily: 'Luciole-Bold'}}>5. LOI APPLICABLE - ATTRIBUTION DE JURIDICTION </Typography>
                        <Typography variant='body1' sx={{fontFamily: 'Luciole'}}>
                                Les Conditions Générales sont régies par le droit français. En cas de litige, les tribunaux français seront seuls compétents.
                                <br/>
                                <br/>
                                Dans le cas où l'une des stipulations des Conditions Générales s'avèrerait nulle, illicite ou inopposable, la validité, la licéité ou l’application des autres stipulations des Conditions Générales n’en sera aucunement affectée ou altérée.
                        </Typography>
                    </Stack>
                </Box>
            </Box>
            <Footer />
        </Box>
    )
}