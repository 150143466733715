import React from 'react';

import MailIcon from '@mui/icons-material/Mail';
import { Box, Stack, Typography, Divider } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';

import { useResponsive } from '../hooks/useResponsive'
import { FooterLink } from '../components/FooterLink'

export const Footer = () => {
    const { isDesktop } = useResponsive()
    const { t } = useTranslation();

    return (
        <footer role="contentinfo">
        <Box width='100%'
          margin='auto'
          backgroundColor='#97c11f'
          paddingY={2}
        >
          <Stack direction={'column'} spacing={2}>
            <Stack direction={isDesktop ? "row": "column"} spacing={2} sx={{flexWrap: 'wrap'}} justifyContent='space-around' alignItems='center'>
              <Stack direction="row" alignItems='center' spacing={1}>
                <MailIcon sx={{color: 'black', fontSize: isDesktop ? '2rem': '1.5rem'}}/>
                <Box component="span" sx={visuallyHidden}>Adresse mail de contact</Box>
                <Typography variant="body1" style={{ color: 'black', fontFamily: 'Luciole', fontSize: isDesktop ? '1.2rem': '1rem' }}>contact@missiontourisme.com</Typography>
              </Stack>
              {isDesktop ? (
                <>
                <Box 
                  component="img"
                  sx={{
                    width: 'auto',
                    height: isDesktop ? 90: 55
                  }}
                  src="herault_departement.png"
                  alt={t('footer.aria-logo-herault')}
                />
                <Box 
                  component="img"
                  sx={{
                    width: 'auto',
                    height: isDesktop ? 75: 45,
                    backgroundColor: 'white',
                    padding: '0.5em'
                  }}
                  src="Herault-Tourisme.jpg"
                  alt={t('footer.aria-herault-tourism')}
                />
              </>
              ): (
                <Stack direction={"row"} spacing={2} alignItems='center'>
                <Box 
                  component="img"
                  sx={{
                    width: 'auto',
                    height: isDesktop ? 90: 55
                  }}
                  src="herault_departement.png"
                  alt={t('footer.aria-logo-herault')}
                />
                <Box 
                  component="img"
                  sx={{
                    width: 'auto',
                    height: isDesktop ? 75: 45,
                    backgroundColor: 'white',
                    padding: '0.5em'
                  }}
                  src="Herault-Tourisme.jpg"
                  alt={t('footer.aria-herault-tourism')}
                />
              </Stack>
              )}
              
              <Box 
                component="img"
                sx={{
                  width: 'auto',
                  height: isDesktop ? 110: 75,
                  maxWidth: 'max-content'
                }}
                src="fds_europeen_feder.jpg"
                alt={t('footer.aria-europeen')}
              />
            
            </Stack>
            <Divider variant='middle' aria-hidden={true} sx={{bgcolor: 'black'}}/>
            <FooterLink />
          </Stack>
        </Box>
      </footer>

    )
}