export const siteList = [
  {
    'title': 'Domaine Départemental de Bayssan',
    'image': 'https://cdn.laetis.fr/i/herault/full_m/https:/www.herault-tourisme.com/uploads/2021/07/cecile-garou-bd-0869.jpg',
    'textFrDesc': 'Voici un magnifique espace de culture et de loisirs à l\'ouest du département.\n\n Un domaine de 160 hectares au parc arboré, autour d\'un patrimoine historique remarquable.\n\n Situé à quelques minutes de Béziers, le domaine de Bayssan est un lieu phare de la culture de l\'Hérault.',
    'textEnDesc': 'Domaine de Bayssan spreads over a 160 hectare area along the A9 motorway.\n\n Domaine de Bayssan offers a wide range of different scenery and areas that enable to stage various types of performing arts: the Big Top area, the garden and the park, the chapel of Saint Felix de Bayssan where some smaller theater plays or classical concerts can be performed.\n\n sortieOuest Big Top was inaugurated in December 2006 and installed at the very heart of Domaine de Bayssan.',
    'textEsDesc': 'Voici un magnifique espace de culture et de loisirs à l\'ouest du département.\n\n Un domaine de 160 hectares au parc arboré, autour d\'un patrimoine historique remarquable.\n\n Situé à quelques minutes de Béziers, le domaine de Bayssan est un lieu phare de la culture de l\'Hérault.',
    'credits': '© Cécile Garou'
  },
  {
    'title': 'Domaine Départemental de Restinclières',
    'image': 'Domaine_departemental_de_Restinclieres_smaller.jpg',
    'textFrDesc': 'Avec son patrimoine naturel exceptionnel s\'étendant sur 240 hectares, ses paysages variés il y en aura pour tous les goûts.\n\n De la simple promenade familiale jusqu\'à la pratique du VTT ou du cheval, toutes les occasions sont bonnes pour fréquenter le domaine, s\'y détendre et, pourquoi pas, s\'y instruire en découvrant la programmation de la Maison Départemental de l\'Environnement, présente dans le château.\n\n Les espaces extérieurs du domaine sont ouverts en permanence et toutes les activités proposées sont gratuites.\n\n Bonne balade !',
    'textEnDesc': 'The Château de Restinclières opens onto a French-style flower garden that descends in terraces and overlooks a landscape of forests and "garrigue" scrubland.\n\n Bordered by the Lez and the Lirou, this 240-hectare estate offers visitors a vast mosaic of landscapes and an exceptional natural heritage.\n\n Rich in the history that saw landowners reap the rewards of pioneering agricultural, hydraulic and botanical techniques, the Department created the Maison Départementale de l\'Environnement here in 1992 to raise public awareness of environmental protection.\n\n From a simple family walk to a mountain bike or horse ride (the "réseau vert" (green network) stretches across the estate), any reason is a good reason to visit the estate, relax and maybe even learn something.',
    'textEsDesc': 'Avec son patrimoine naturel exceptionnel s\'étendant sur 240 hectares, ses paysages variés il y en aura pour tous les goûts.\n\n De la simple promenade familiale jusqu\'à la pratique du VTT ou du cheval, toutes les occasions sont bonnes pour fréquenter le domaine, s\'y détendre et, pourquoi pas, s\'y instruire en découvrant la programmation de la Maison Départemental de l\'Environnement, présente dans le château.\n\n Les espaces extérieurs du domaine sont ouverts en permanence et toutes les activités proposées sont gratuites.\n\n Bonne balade !',
    'credits': '© Philippe Hilaire - Conseil Départemental de l\'Hérault'
  },
  {
    'title': 'Domaine Départemental de Bessilles',
    'image': 'Parc_departemental_de_Bessilles_smaller.jpg',
    'textFrDesc': 'Ce domaine départemental, entièrement dédié à l\'accueil des visiteurs, propose une large palette d\'activités sportives et environnementales.\n\n Le site de Bessilles s\'est doté d\'un plan de gestion forestier et écologique afin d\'assurer la pérennité de ses milieux naturels et de favoriser la biodiversité.\n\n Le parc départemental vous accueille pour un moment de détente autour de l\'aire de pique aménagée et de son aire de jeux pour enfants.',
    'textEnDesc': '35 ha of nature dedicated to sports and leisure.\n\n An area to relax in a Mediterranean environment, equipped with barbecues, picnic areas, places to rest.\n\n Ideal for walks, biking trails (starting point of numerous mountain bike trails, all levels, mountain bikes rental).\n\n Swimming pool, children playgrounds, multi sports fields, interpretation panels for ecology.\n\n Some of the equipment are not free of charge.',
    'textEsDesc': 'Ce domaine départemental, entièrement dédié à l\'accueil des visiteurs, propose une large palette d\'activités sportives et environnementales.\n\n Le site de Bessilles s\'est doté d\'un plan de gestion forestier et écologique afin d\'assurer la pérennité de ses milieux naturels et de favoriser la biodiversité.\n\n Le parc départemental vous accueille pour un moment de détente autour de l\'aire de pique aménagée et de son aire de jeux pour enfants.',
    'credits': '© Christophe Cambon - Conseil Départemental de l\'Hérault'
  },
  {
    'title': 'Port Départemental du Chichoulet',
    'image': 'Chichoulet_EID_2015_smaller.jpg',
    'textFrDesc': 'Dernier port de plaisance de la côte héraultaise vers l\'ouest, le port du Chichoulet est situé au sein d\'une zone Natura 2000.\n\n Eloigné du village de Vendres, il bénéficie des avantages de la présence de la base conchylicole et des kiosques de dégustation, ainsi qu\'un dynamisme lié aux nombreuses activités et manifestations proposées.',
    'textEnDesc': 'The last marina on the Herault coast to the west, the port of Chichoulet is located in a Natura 2000 area.\n\n Far from the village of Vendres, it benefits from the advantages of the presence of the shellfish farming base and the tasting kiosks, as well as a dynamism linked to the numerous activities and events offered.',
    'textEsDesc': 'Último puerto deportivo de la costa del Hérault hacia el oeste, el puerto de Chichoulet está situado en una zona Natura 2000.\n\n Lejos del pueblo de Vendres, se beneficia de las ventajas de la presencia de la base marisquera y de los quioscos de degustación, así como de un dinamismo ligado a las numerosas actividades y eventos que se ofrecen.'
  },
  {
    'title': 'Montpellier - Coeur de ville',
    'image': 'Rives_du_Lez_Montpellier_smaller.jpg',
    'textFrDesc': 'Bienvenue à Montpellier, la ville entourée de nature ! Cosmopolite, la capitale du Languedoc sait être glam et populaire, millénaire et moderne, paisible et hyperactive...\n\n Internationale, culturelle et festive toute l\'année, elle vit, elle bouge, elle grandit ! Ciel bleu et lumière douce vous invitent à flâner en terrasse, dans ses ruelles, le long du Lez, ou à pédaler jusqu\'à la mer...\n\n Respirez et prenez un grand bol d\'air.\n\n A Montpellier, on vit dehors !',
    'textEnDesc': 'Bienvenue à Montpellier, la ville entourée de nature ! Cosmopolite, la capitale du Languedoc sait être glam et populaire, millénaire et moderne, paisible et hyperactive...\n\n Internationale, culturelle et festive toute l\'année, elle vit, elle bouge, elle grandit ! Ciel bleu et lumière douce vous invitent à flâner en terrasse, dans ses ruelles, le long du Lez, ou à pédaler jusqu\'à la mer...\n\n Respirez et prenez un grand bol d\'air.\n\n A Montpellier, on vit dehors !',
    'textEsDesc': 'Bienvenue à Montpellier, la ville entourée de nature ! Cosmopolite, la capitale du Languedoc sait être glam et populaire, millénaire et moderne, paisible et hyperactive...\n\n Internationale, culturelle et festive toute l\'année, elle vit, elle bouge, elle grandit ! Ciel bleu et lumière douce vous invitent à flâner en terrasse, dans ses ruelles, le long du Lez, ou à pédaler jusqu\'à la mer...\n\n Respirez et prenez un grand bol d\'air.\n\n A Montpellier, on vit dehors !',
    'credits': '© ADT34 - Anthony Allies'
  },
  {
    'title': 'Ambrussum, site archéologique et musée - Villetelle',
    'image': 'oppidum-et-le-pont-Ambroix_smaller.jpg',
    'textFrDesc': 'Ambrussum est un site archéologique occupé depuis plus de 2500 ans qui, dans un cadre naturel préservé, illustre parfaitement la romanisation des territoires du sud de la Gaule.\n\n Au milieu du Vidourle, trône encore la dernière arche du pont romain qui permettait à la Via Domitia d\'accéder au village fortifié au sommet de la colline.\n\n Le musée retrace l\'histoire du site, des premières occupations par l\'Homme, jusqu\'aux fouilles archéologiques.\n\n En autonomie : visite libre et gratuite pour tous.\n Toute l\'année, l\'équipe du musée propose de nombreuses animations pour découvrir les lieux : visites guidées, livrets jeux, atelier, conférence, événements, expositions...',
    'textEnDesc': 'Ambrussum, archaeological site with its celebrated place in antiquity and the Ambroix bridge has also brought to light a Gaulish oppidum surrounded by ramparts, a Roman staging post on the Via Domitia, Gallo-Roman homes and a paved roadway more than 2000 years old.\n\n The museum recounts Ambrussum\'s history from the first occupation by mankind to the archaeological excavations.',
    'textEsDesc': 'Ambrussum est un site archéologique occupé depuis plus de 2500 ans qui, dans un cadre naturel préservé, illustre parfaitement la romanisation des territoires du sud de la Gaule.\n\n Au milieu du Vidourle, trône encore la dernière arche du pont romain qui permettait à la Via Domitia d\'accéder au village fortifié au sommet de la colline.\n\n Le musée retrace l\'histoire du site, des premières occupations par l\'Homme, jusqu\'aux fouilles archéologiques.\n\n En autonomie : visite libre et gratuite pour tous.\n Toute l\'année, l\'équipe du musée propose de nombreuses animations pour découvrir les lieux : visites guidées, livrets jeux, atelier, conférence, événements, expositions...',
    'credits': '© Go-Production'
  },
  {
    'title': 'Marseillan village , autour du Port, Archipel de Thau',
    'image': 'Marseillan_port_-_EXCLUSIF_ADT34_Didier_Cavailhes_8_smaller.jpg',
    'textFrDesc': 'Ancien village de pêcheurs, Marseillan est un port de plaisance où se nichent de splendides maisons vigneronnes.\n\n Autour, un patrimoine naturel préservé, une faune et une flore resplendissantes.\n De la visite de la Maison Noilly Prat, dont la réputation du vermouth est mondialement connue à la dégustation des produits de la mer sur les quais... autant de délices à savourer en famille ou entre amis !',
    'textEnDesc': 'A former fishing village, Marseillan is a marina where splendid winegrowers\' houses nestle.\n\n Around, a preserved natural heritage, a resplendent fauna and flora.\n From visiting Maison Noilly Prat, whose reputation for vermouth is world famous, to tasting seafood on the docks… so many delights to savor with family or friends!',
    'textEsDesc': 'Antiguo pueblo de pescadores, Marseillan es un puerto deportivo donde anidan espléndidas casas de viticultores.\n\n Alrededor, un patrimonio natural preservado, una fauna y una flora resplandecientes.\n Desde visitar la Maison Noilly Prat, cuya reputación por el vermut es mundialmente conocida, hasta degustar mariscos en los muelles… ¡Tantas delicias para saborear en familia o con amigos!',
    'credits': '© ADT34 - Didier Cavailhes'
  },
  {
    'title': 'Plage de Villeroy - Sète, Archipel de Thau',
    'image': 'https://cdn.laetis.fr/i/herault/full/https:/www.herault-tourisme.com/uploads/2022/06/entree-de-la-plage-a-setem-sanz.jpg',
    'textFrDesc': 'L\'île singulière, ou "la Venise du Languedoc", est une destination à la fois culturelle et authentique!\n\n Ses 6 Musées, ses festivals dans le prestigieux théâtre de la mer, ses quartiers historiques où il fait bon flâner du Quartier Haut à la Pointe courte, en passant par le Mont Saint-Clair, ses plages bordées de sable fin, son ensoleillement quasi-permanent appréciable le long de la voie verte, rejoignant Balaruc-les-Bains ou Marseillan, et ses spécialités culinaires…\n\n Sète est "l\'incontournable" !',
    'textEnDesc': 'The singular island, or "the Venice of Languedoc", is both a cultural and authentic destination!\n\n Its 6 museums, its festivals in the prestigious theater of the sea, its historic districts where it is good to stroll from the Quartier Haut to the Pointe courte, passing by Mont Saint-Clair, its beaches lined with fine sand, its almost significant permanent along the greenway, joining Balaruc-les-Bains or Marseillan, and its culinary specialties...\n\n Sète is "the essential" !',
    'textEsDesc': '¡La isla singular, o "la Venecia de Languedoc", es un destino cultural y auténtico!\n\n Sus 6 museos, sus festivales en el prestigioso teatro del mar, sus barrios históricos donde es bueno pasear desde el Quartier Haut hasta la Pointe Courte, pasando por el Mont Saint-Clair, sus playas bordeadas de arena fina, su sol casi significativo permanente a lo largo de la vía verde, uniendo Balaruc-les-Bains o Marseillan, y sus especialidades culinarias...\n\n ¡Sète es "lo esencial"!',
    'credits': '© M Sanz'
  },
  {
    'title': 'Grand Site de France (en projet) - Salagou-Cirque de Mourèze - Berges de Clermont-l\'Hérault',
    'image': 'Mont_Liausson_-_EXCLUSIF_ADT34_Sam_Bie_11_smaller.jpg',
    'textFrDesc': 'En plein coeur de l\'Hérault et du Languedoc, voici un lac à nul autre pareil.\n Ce qui le rend exceptionnel, c\'est d\'abord le contraste de l\'eau et de ce paysage aride.\n\n Mais c\'est aussi une richesse géologique et une biodiversité quasi unique au monde.\n Cette combinaison donne naissance à un merveilleux écrin pour les activités de loisirs, ou simplement pour profiter du grand air en famille.',
    'textEnDesc': '50 kilometres to the north-west of Montpellier, visit the Lodève area, its cultural and religious heritage, its landscapes.\n\n This route leaves Lodève, a town of art and history.\n\n Cross the Lodévois, the Mediterranean plain and the terrace cultivation.\n\n Visit the religious heritage, hidden villages where the "soul" of pilgrims to Santiago de Compostela floats.\n\n Lake Salagou offers a stopover in its setting of red pillars.',
    'textEsDesc': 'En plein coeur de l\'Hérault et du Languedoc, voici un lac à nul autre pareil.\n Ce qui le rend exceptionnel, c\'est d\'abord le contraste de l\'eau et de ce paysage aride.\n\n Mais c\'est aussi une richesse géologique et une biodiversité quasi unique au monde.\n Cette combinaison donne naissance à un merveilleux écrin pour les activités de loisirs, ou simplement pour profiter du grand air en famille.',
    'credits': '© ADT34 - Sam Bie'
  },
  {
    'title': 'Saint-Guilhem - Vallée de l\'Hérault',
    'image': 'Saint_Guilhem_Vallee_de_l_Herault_smaller.jpg',
    'textFrDesc': 'C\'est un incontournable.\n\n Il trône, majestueux, sur l\'Hérault depuis plus d\'un millénaire, et c\'est sans doute l\'un des plus vieux ponts romans de France.\n\n Le Pont du Diable "verrouille" l\'entrée des gorges de l\'Hérault.\n Cette transition franche entre les plaines viticoles du Languedoc et les gorges escarpées de l\'Hérault, en fait un lieu mythique parmi tous.\n\n Le Pont est aujourd\'hui auréolé de titres : inscrit aux Monuments Historiques depuis 1935, sur la liste du Patrimoine Mondial de l\'UNESCO, et l\'une des pierres angulaires du Grand Site de France des Gorges de l\'Hérault.\n\n Un pont légendaire, on vous dit !',
    'textEnDesc': 'C\'est un incontournable.\n\n Il trône, majestueux, sur l\'Hérault depuis plus d\'un millénaire, et c\'est sans doute l\'un des plus vieux ponts romans de France.\n\n Le Pont du Diable "verrouille" l\'entrée des gorges de l\'Hérault.\n Cette transition franche entre les plaines viticoles du Languedoc et les gorges escarpées de l\'Hérault, en fait un lieu mythique parmi tous.\n\n Le Pont est aujourd\'hui auréolé de titres : inscrit aux Monuments Historiques depuis 1935, sur la liste du Patrimoine Mondial de l\'UNESCO, et l\'une des pierres angulaires du Grand Site de France des Gorges de l\'Hérault.\n\n Un pont légendaire, on vous dit !',
    'textEsDesc': 'C\'est un incontournable.\n\n Il trône, majestueux, sur l\'Hérault depuis plus d\'un millénaire, et c\'est sans doute l\'un des plus vieux ponts romans de France.\n\n Le Pont du Diable "verrouille" l\'entrée des gorges de l\'Hérault.\n Cette transition franche entre les plaines viticoles du Languedoc et les gorges escarpées de l\'Hérault, en fait un lieu mythique parmi tous.\n\n Le Pont est aujourd\'hui auréolé de titres : inscrit aux Monuments Historiques depuis 1935, sur la liste du Patrimoine Mondial de l\'UNESCO, et l\'une des pierres angulaires du Grand Site de France des Gorges de l\'Hérault.\n\n Un pont légendaire, on vous dit !',
    'credits': '© OTI Saint Guilhem Vallee de l\'Herault',
    'grandSite': true
  },
  {
    'title': 'Site archéologique oppidum et musée d\'Ensérune Nissan lez Ensérune',
    'image': 'Oppidum_d_enserune_la_domitienne_ExclusifADT34_Olivier-Octobre_smaller.jpg',
    'textFrDesc': 'Quand on arrive des plaines de Béziers, de Narbonne ou des vignobles de Saint-Chinian, on ne distingue qu\'une colline ordinaire.\n\n Et pourtant, l\'Oppidum d\'Ensérune constitue l\'un des joyaux de l\'Hérault, un paysage hors du commun et un concentré de sites retraçant toute l\'histoire du Languedoc...',
    'textEnDesc': 'This is a remarkable archeological site situated at the foot of the Oppidum d\'Ensérune (an ancient hill fort).\n\n Originally an ancient brackish water marsh that was the source of various epidemics, it was cleaned up in 1270.\n\n Ditches radiating outwards from a central point like sunrays were built so that the 420 hectares of land could be worked.\n\n The network of canals allowed the water to drain from the perimeter to the centre.\n\n This water was then directed towards the Clavilongue stream using a gallery system some 1300 metres long.\n\n The whole ensemble is a masterpiece of mediaeval hydro-agricultural engineering.\n\n The Oppidum d\'Ensérune also offers an exceptional viewpoint.',
    'textEsDesc': 'Quand on arrive des plaines de Béziers, de Narbonne ou des vignobles de Saint-Chinian, on ne distingue qu\'une colline ordinaire.\n\n Et pourtant, l\'Oppidum d\'Ensérune constitue l\'un des joyaux de l\'Hérault, un paysage hors du commun et un concentré de sites retraçant toute l\'histoire du Languedoc...',
    'credits': '© ADT34 - Olivier Octobre'
  },
  {
    'title': 'Port du Cap d\'Agde',
    'image': 'Plage_Roquille_smaller.jpg',
    'textFrDesc': 'Superbe port que celui du Cap d\'Agde ! 2ème port de plaisance français.\n\n Station balnéaire aux milles facettes.\n Plages, paillottes, discothèques, activités nautiques, animations...\n\n Venir au Cap d\'Agde c\'est profiter d\'une multitude d\'activités face à la mer ou tout simplement se reposer. \n\n Pour plus d\'informations, consulter le site: <a href="https://www.capdagde.com/fr/cap-dagde-mediterranee-accessible/">Cap d\'Agde Méditerranée accessible</a>',
    'textEnDesc': 'Supervised beach with first-aid station nearby.\n\n Disabled parking also located nearby with wheelchair access from the car park to the swimming area.\n\n Reception area with hard flooring at the first-aid station, disabled toilet facilities less than 100 metres away, voice systems to help blind and partially sighted swimmers (Audioplage), floating wheelchairs (Tiralos, Hippocampes), specially trained helpers for disabled guests ("handiplagistes") and sun shades.\n\n For more information, consult the website: <a href="https://www.capdagde.com/fr/cap-dagde-mediterranee-accessible/">Cap d\'Agde Méditerranée accessible</a>',
    'textEsDesc': 'Superbe port que celui du Cap d\'Agde ! 2ème port de plaisance français.\n\n Station balnéaire aux milles facettes.\n Plages, paillottes, discothèques, activités nautiques, animations...\n\n Venir au Cap d\'Agde c\'est profiter d\'une multitude d\'activités face à la mer ou tout simplement se reposer. \n\n Para más información, visite la página web: <a href="https://www.capdagde.com/fr/cap-dagde-mediterranee-accessible/">Cap d\'Agde Méditerranée accessible</a>',
    'credits': '© OT Cap d\'Agde Méditerranée'
  },
  {
    'title': 'Destination Pour Tous, Station thermale Balaruc les Bains, Archipel de Thau',
    'image': 'https://cdn.laetis.fr/i/herault/full/https:/www.herault-tourisme.com/uploads/2021/01/usage-unique-site-web-go-production-balaruc-tourisme2.jpg',
    'textFrDesc': 'Balaruc-les-Bains, marquée Destination pour tous, est la 1ère station thermale de France (rhumatologie et phlébologie).\n\n Idéalement située au bord de la lagune de Thau, elle accueille chaleureusement de nombreux curistes et promeneurs qui apprécient cette douce qualité de vie, entre visites nature, découverte du patrimoine, dégustation de produits du terroir, et magnifique coucher du soleil…un pied de nez au temps !',
    'textEnDesc': 'Balaruc-les-Bains, marked Destination for all, is the 1st spa resort in France (rheumatology and phlebology).\n\n Ideally located on the edge of the Thau lagoon, it warmly welcomes many spa guests and walkers who appreciate this gentle quality of life, between nature visits, discovery of heritage, tasting of local products, and beautiful sunset...a snub at the time !',
    'textEsDesc': 'Balaruc-les-Bains, marcado Destino para todos, es el primer balneario de Francia (reumatología y flebología).\n\n Con una ubicación ideal al borde de la laguna de Thau, acoge cálidamente a muchos huéspedes del spa y caminantes que aprecian esta calidad de vida suave, entre visitas a la naturaleza, descubrimiento de un patrimonio, degustación de productos locales y precioso atardecer... ¡un desaire al tiempo!',
    'credits': '© Go production - Balaruc Tourisme',
    'destinationPourTous': true
  },
  {
    'title': 'Station thermale Lamalou les Bains - Voie verte et coeur de ville',
    'image': 'https://www.occitanie-thermale.com/uploads/2021/01/thermes-lamalou-les-bains-piscine-corentin-mossiere.jpg',
    'textFrDesc': 'Dans le parc naturel régional du Haut-Languedoc, entre vignobles et contreforts des Cévennes, la station thermale méridionale de Lamalou-les-Bains vous accueille dans un cadre verdoyant, propice à la récupération et au bien-être : une pause salutaire, pour mettre entre parenthèses le stress du quotidien.\n\n La qualité des eaux thermales, dont les vertus thérapeutiques ont été mises en lumière par le Docteur Charcot, fait de Lamalou-les-Bains, l\'un des rares centres thermaux français à soigner la Neurologie et la Rhumatologie simultanément.\n\n Lamalou-les-bains est une station de la Chaîne Thermale du Soleil.',
    'textEnDesc': 'The treatments at Lamalou-les-Bains focus on three main areas: neurology, rheumatology and traumatology.\n\n The medical staff, who are made up of specialists in thermal medicine, have a wide range of sophisticated thermal treatments at their disposal, and access to the latest developments in hydrological technology.',
    'textEsDesc': 'Dans le parc naturel régional du Haut-Languedoc, entre vignobles et contreforts des Cévennes, la station thermale méridionale de Lamalou-les-Bains vous accueille dans un cadre verdoyant, propice à la récupération et au bien-être : une pause salutaire, pour mettre entre parenthèses le stress du quotidien.\n\n La qualité des eaux thermales, dont les vertus thérapeutiques ont été mises en lumière par le Docteur Charcot, fait de Lamalou-les-Bains, l\'un des rares centres thermaux français à soigner la Neurologie et la Rhumatologie simultanément.\n\n Lamalou-les-bains est une station de la Chaîne Thermale du Soleil.',
    'credits': '© occitanie-thermale.com'
  },
  {
    'title': 'Destination Pour Tous, Colombiers - Port fluvial - canal du Midi',
    'image': 'PaysageAerienColombiers-CanalduMidi-SandraBereniceMichel_smaller.jpg',
    'textFrDesc': 'Envie d\'un break dans un lieu paisible ? Direction le port fluvial de Colombiers ! Entièrement aménagé, l\'espace est grand et fleuri.\n\n Un panorama d\'exception s\'offre à vous avec en toile de fond le célèbre Canal du Midi.\n\n Détente à la terrasse d\'un café, promenade le long des quais, navigation sur le canal pour découvrir le Tunnel du Malpas.\n\n Tout est possible… Osez vivre cette expérience unique !',
    'textEnDesc': 'Envie d\'un break dans un lieu paisible ? Direction le port fluvial de Colombiers ! Entièrement aménagé, l\'espace est grand et fleuri.\n\n Un panorama d\'exception s\'offre à vous avec en toile de fond le célèbre Canal du Midi.\n\n Détente à la terrasse d\'un café, promenade le long des quais, navigation sur le canal pour découvrir le Tunnel du Malpas.\n\n Tout est possible… Osez vivre cette expérience unique !',
    'textEsDesc': 'Envie d\'un break dans un lieu paisible ? Direction le port fluvial de Colombiers ! Entièrement aménagé, l\'espace est grand et fleuri.\n\n Un panorama d\'exception s\'offre à vous avec en toile de fond le célèbre Canal du Midi.\n\n Détente à la terrasse d\'un café, promenade le long des quais, navigation sur le canal pour découvrir le Tunnel du Malpas.\n\n Tout est possible… Osez vivre cette expérience unique !',
    'credits': '© Sandra Bérénice Michel',
    'destinationPourTous': true
  },
  {
    'title': 'Destination Pour Tous, Lespignan - Coeur de village',
    'image': 'Belvedere-de-Lespignan.jpg',
    'textFrDesc': 'Lespignan, est la destination idéale pour découvrir une nature préservée inscrite à Natura 2000.\n Tout le monde peut en profiter.\n\n Envie d\'une balade en joëlette ? Contactez l\'association Sports Passions, le service et la bonne humeur seront au rendez-vous.\n\n C\'est aussi l\'occasion de découvrir un village méridional, dont les ruelles vous guideront vers le château.\n\n Vous pourrez alors profiter d\'espaces aménagés pour le repose, la contemplatation et des magnifiques coucher de soleil',
    'textEnDesc': 'Lespignan, est la destination idéale pour découvrir une nature préservée inscrite à Natura 2000.\n Tout le monde peut en profiter.\n\n Envie d\'une balade en joëlette ? Contactez l\'association Sports Passions, le service et la bonne humeur seront au rendez-vous.\n\n C\'est aussi l\'occasion de découvrir un village méridional, dont les ruelles vous guideront vers le château.\n\n Vous pourrez alors profiter d\'espaces aménagés pour le repose, la contemplatation et des magnifiques coucher de soleil',
    'textEsDesc': 'Lespignan, est la destination idéale pour découvrir une nature préservée inscrite à Natura 2000.\n Tout le monde peut en profiter.\n\n Envie d\'une balade en joëlette ? Contactez l\'association Sports Passions, le service et la bonne humeur seront au rendez-vous.\n\n C\'est aussi l\'occasion de découvrir un village méridional, dont les ruelles vous guideront vers le château.\n\n Vous pourrez alors profiter d\'espaces aménagés pour le repose, la contemplatation et des magnifiques coucher de soleil',
    'destinationPourTous': true
  },
  {
    'title': 'Destination Pour Tous, Carnon - Port',
    'image': 'Piste_cyclable_au_Petit_Travers_a_Carnon_smaller.jpg',
    'textFrDesc': 'Carnon, une petite station balnéaire comme on les aime...\n\n sans stress, sans strass avec bonne humeur, proximité et convivialité',
    'textEnDesc': 'Carnon, une petite station balnéaire comme on les aime...\n\n sans stress, sans strass avec bonne humeur, proximité et convivialité',
    'textEsDesc': 'Carnon, une petite station balnéaire comme on les aime...\n\n sans stress, sans strass avec bonne humeur, proximité et convivialité',
    'credits': '© H. Leclair - Aspheries - Hérault Tourisme',
    'destinationPourTous': true
  },
  {
    'title': 'Destination Pour Tous Mauguio - Coeur de ville',
    'image': 'Restaurant_sur_la_Place_de_l_Hotel_de_ville_a_ Mauguio_smaller.jpg',
    'textFrDesc': '2020, date où la ville de Mauguio-Carnon est marquée Destination Pour Tous.\n\n Vous trouverez toujours un lieu à visiter, une activité à pratiquer, une plage sur laquelle vous reposer et vous baigner… Il y en a pour tous les goûts !',
    'textEnDesc': '2020, date où la ville de Mauguio-Carnon est marquée Destination Pour Tous.\n\n Vous trouverez toujours un lieu à visiter, une activité à pratiquer, une plage sur laquelle vous reposer et vous baigner… Il y en a pour tous les goûts !',
    'textEsDesc': '2020, date où la ville de Mauguio-Carnon est marquée Destination Pour Tous.\n\n Vous trouverez toujours un lieu à visiter, une activité à pratiquer, une plage sur laquelle vous reposer et vous baigner… Il y en a pour tous les goûts !',
    'credits': '© H. Leclair - Aspheries - Hérault Tourisme',
    'destinationPourTous': true
  }
];