import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { config } from '../config';
import { useTranslation } from 'react-i18next';

export default function ImgMediaCard({ title, credits ,textDesc, image, grandSite, destinationPourTous}) {
  const { i18n, t } = useTranslation()
  const markup = { __html: `<p>${textDesc}</p>` };

  const [direction, setDirection] = useState('row');

  useEffect(() => {
    if(grandSite && destinationPourTous) {
      setDirection('column');
    }
  });

  return (
    <Card sx={{ ':hover': {'transform': 'translateY(-10px)'}, ':focus': {'transform': 'translateY(-10px)'}}}>
      <CardActionArea href={`${config.url.HANDIMAP_URL}?zone=${title}&lng=${i18n.language}`}>
        <Box sx={{width:'100%', position: 'relative'}}>
          <CardMedia
            component="img"
            alt=""
            height="200"
            image={image}
          />
          {credits && 
            <Typography 
              aria-hidden='true'
              variant="p"
              component="p"
              sx={{
                position: 'absolute',
                bottom:'0.2em',
                left:'0.2em', 
                fontFamily: 'Luciole', 
                fontSize:'0.7em', 
                backgroundColor:'white', 
                opacity: '0.8', 
                paddingTop:'0.2em',
                paddingX:'0.2em',
                borderRadius: '0.2em'
              }}
            >
              {credits}
            </Typography>
          }
        </Box>
        <CardContent>
          <Stack alignItems="center" direction="row" spacing={1}>
            <Typography gutterBottom variant="h5" component="h3">
              {title}
            </Typography>
            
            {destinationPourTous && 
              <Box 
                component="img"
                sx={{
                  height: '7em'
                }} 
                src="destination-pour-tous.png"
                alt={t('site.destination-pour-tous')}
              />
            }
          </Stack>
          {grandSite && 
            <Box sx={{
                justifyContent: "center", display: "flex"
              }}>
              <Box 
                component="img"
                sx={{
                  height: '6.5em',
                  padding: '1em'
                }} 
                src="Logo_-_Grand_Site_de_France.png"
                alt={t('site.grand-site')}
              />
            </Box>
          }
          <Typography variant="body2" color="text.secondary" sx={{whiteSpace: 'pre-line'}}>
            <div dangerouslySetInnerHTML={markup}/>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}