import React, { useEffect } from 'react';

import { Box, ListItem, List, Link  } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { siteList } from '../siteList';
import { config } from '../config';
import { Footer } from '../containers/Footer';
import { HeaderTitle } from '../containers/HeaderTitle';

const CustomListItem = styled(ListItem)`
    display: list-item;
    font-family: Luciole;
    color: #3030303;
`
const CustomLink = styled(Link)`
    color: inherit;
    text-decoration: none;
    &:focus, &:hover {
        text-decoration: underline;
    }
`

export const SiteMap = () => {
    const { t, i18n } = useTranslation()
    useEffect(() => {
        window.scrollTo({
            top: 0
        })
    }, [])

    useEffect(() => {
        document.title=`${t('link.label-plan')} | ${t('common.aria-logo')}`
    }, [t])

    return (
    <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
        <HeaderTitle title={t('link.label-plan')} />
        <Box sx={{
            display: 'flex', 
            justifyContent: 'center'
        }}>
            <Box sx={{display: 'flex', paddingX: '2em', paddingY: '2em', width: '72.5em', flexDirection: 'column'}}>
                <CustomListItem>
                    <CustomLink href='../#acceuil'>
                        {t('home.title')}
                    </CustomLink>
                </CustomListItem>
                <CustomListItem>
                    <CustomLink href='../#sites'>
                        {t('site.title')}
                    </CustomLink>
                    <List sx={{
                        paddingLeft: '3em',                        
                        listStyleType: 'disc',
                        '& .MuiListItem-root': {
                        display: 'list-item',
                        },
                    }}>
                        <>
                            {
                                siteList.map((site, index) => {
                                return (
                                    <ListItem key={index}>
                                        <CustomLink href={`${config.url.HANDIMAP_URL}?zone=${site.title}&lng=${i18n.language}`}>
                                            {site.title}
                                        </CustomLink>
                                    </ListItem>
                                )
                                })
                            }
                            <ListItem>
                                <CustomLink href={`${config.url.HANDIMAP_URL}?zone=Département de l'Hérault&lng=${i18n.language}`}>
                                    {t('site.herault')}
                                </CustomLink>
                            </ListItem>
                        </>
                    </List>
                </CustomListItem>
                <CustomListItem>
                    <CustomLink href='../#numbers'>
                        {t('phone.title')}
                    </CustomLink>
                </CustomListItem>
                <CustomListItem>
                    <CustomLink href='../#beach'>
                        {t('beach.title')}
                    </CustomLink>
                </CustomListItem>
            </Box>
        </Box>
        <Footer />
    </Box>
    )
}